import request from "@/utils/request";

export function getUserInfo() {
  return request({
    url: `/api/user/get_info`,
    method: "post",
    data: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
}
export function updateUserInfo(data) {
  return request({
    url: "/api/user/info_edit",
    method: "post",
    data,
  });
}
export function avatarEdit(file) {
  return request({
    url: `/api/user/info/avatar_edit`,
    method: "post",
    data: {
      file: file,
    },
  });
}
export function getCardList(n, m) {
  return request({
    url: `/api/user/get_card_lists?page=${n}&page_size=${m}`,
    method: "post",
  });
}
