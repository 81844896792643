import axios from "axios";
import { getCookie } from "@/utils/operateCookie";
/* eslint-disable */
// 可以批量下载的音频解析
const crypto = window.crypto || window.msCrypto;
//tunefab  dumpmedia
const vendor = "tunefab";
const key = "WPm837w1Kt2lRKidXALXmUqLHfrb28SJ";
const iv = crypto.getRandomValues(new Uint8Array(16));
const encoder = new TextEncoder();
let importedKey;
(async () => {
  importedKey = await crypto.subtle.importKey("raw", encoder.encode(key), "AES-CBC", false, ["encrypt", "decrypt"]);
})();

async function encryptData(data) {
  const ciphertext = await crypto.subtle.encrypt(
    { name: "AES-CBC", iv: iv },
    importedKey,
    encoder.encode(JSON.stringify(data))
  );
  return btoa(btoa(String.fromCharCode(...new Uint8Array(ciphertext))) + `:::${String.fromCharCode(...iv)}`);
}

export default class EncryptRequest {
  constructor(params, timeout) {
    if (typeof params !== "string") {
      this.req = params;
      return;
    }
    this.req = axios.create({
      baseURL: params,
      timeout: timeout || 20000
    });
    this.req.interceptors.request.use(
      config => {
        if (getCookie("_token")) {
          config.headers["Authorization"] = "Bearer " + getCookie("_token");
        }
        return config;
      }
    );
    this.req.interceptors.response.use(
      response => {
        return response.data;
      },
      error => {
        return Promise.reject(error.response);
      }
    );
  }

  async request(type, data, flag = 0) {
    const text = await encryptData(data);
    const params = {
      gateway_type: type,
      rule_tag: "online_v1.0",
      cipher_text: text,
      vendor,
      permission_tag:"smc"
    };
    switch (flag) {
      case 1:
        params["rules_data"] = {
          cycle_time_and_incre_value_video_download: 1
        };
        break;
      case 2:
        params["rules_data"] = {
          incre_value_music_download: data.ids.length
        };
        break;
    }
    return this.req.post("/api/multimedia/gateway", params);
  }

  analyseAudio(url) {
    return this.request(
      "music_analyse",
      {
        url,
      }
    );
  }

  searchTask(id, type, time = 5000, maxRetry = 12) {
    let isSearchTask = false,
      searchNum = 1,
      searchTimer = null;
    return new Promise((resolve, reject) => {
      const queryQueue = async () => {
        if (isSearchTask) return;
        isSearchTask = true;
        searchNum++;
        this.request("query_result", { key: id, type })
          .then(res => {
            isSearchTask = false;
            clearTimeout(searchTimer);
            if (res.message == "Success") {
              if (res.data.finish) {
                resolve(res);
                return;
              }
              if (searchNum > maxRetry) return reject("已请求超过最大次数");
              searchTimer = setTimeout(() => {
                queryQueue(id);
              }, time);
            } else {
              reject(false);
            }
          })
          .catch(err => {
            reject(err);
          });
      };

      queryQueue();
    });
  }

  remainCount(){
    return this.request("count_left",{type:'vip_info'});
  }

  downloadList(list) {
    return this.request("music_batch_download", {  ids: list.map(item => item.id)},
    2);
  }
}

if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
  module.exports = EncryptRequest;
} else {
  window.EncryptRequest = EncryptRequest;
}
