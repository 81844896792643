<template>
  <div class="register">
    <p class="title">{{ $t("dm_reg.create") }}</p>
    <p class="text">{{ $t("dm_reg.fill_form") }}</p>
    <custom-form
      ref="form"
      :formInfo="regInfo"
      :bottomLink="{
        href: '/login',
        text: $t('login.login'),
      }"
      :confirmText="$t('dm_login.reg')"
    >
      <template v-slot:pwdTip>
        <p class="pwd-tip">{{ $t("register.pwd_limit") }}</p>
      </template>
      <template v-slot:beforeBtn>
        <el-form-item>
          <el-checkbox
            el-checkbox
            v-model="checked"
            :label="$t('register.check_tip')"
          ></el-checkbox>
          <el-checkbox
            el-checkbox
            v-model="acceptNewsletter"
            :label="$t('register.receive_newsletter')"
          ></el-checkbox>
        </el-form-item>
      </template>
      <template v-slot:beforeOr>
        <div class="third-party-login" v-if="vendorInfo.hasGoogleLogin">
          <google-login
            :isNewCustomerActive="isNewCustomerActive"
            :other="other"
            :page="'register'"
            :acceptNewsletterValue="accept_newsletter"
            :fp="fp"
          >
          </google-login>
        </div>
      </template>
    </custom-form>
  </div>
</template>

<script>
import customForm from "../customForm.vue";
import GoogleLogin from "../googleLogin.vue";
import { register } from "@/api/login.js";
import { mapGetters } from "vuex";

export default {
  name: "registerComponent",
  components: { customForm, GoogleLogin },
  data() {
    return {
      regInfo: {
        first_name: {
          type: "name",
          value: "",
        },
        last_name: {
          type: "name",
          value: "",
        },
        email: {
          type: "email",
          value: "",
        },
        password: {
          type: "password",
          value: "",
          slotName: "pwdTip",
        },
        password_confirmation: {
          type: "password",
          value: "",
        },
      },
      checked: false,
      acceptNewsletter: true,
      form: null,
      other: {},
      isNewCustomerActive: false,
    };
  },
  props: {
    fp: {
      type: String,
    },
  },
  computed: {
    accept_newsletter() {
      return this.acceptNewsletter ? 1 : 0;
    },
    ...mapGetters(["vendorInfo"]),
  },
  mounted() {
    this.form = this.$refs.form;

    if (this.$route.query.redirect) {
      this.isNewCustomerActive = true;
      // 非同源页面，document.referrer只携带请求的域名
      this.other = {
        source_page: this.$route.query.source,
        op_system: navigator.platform,
        activity_source: 1,
      };
    }
  },
  methods: {
    beforeValidate() {
      if (!this.checked) {
        this.form.errorTip = this.$t("register.please_accept");
        return false;
      }
      return true;
    },
    handleConfirm() {
      const info = this.form.getReqInfo();
      register({
        ...info,
        ...this.other,
        accept_newsletter: this.accept_newsletter,
        finger_print: this.fp,
        fp_version: window.fpVersion,
        language: this.$i18n.locale ? this.$i18n.locale.slice(0, 2) : "en",
      })
        .then(res => {
          this.form.isLoading = false;
          const d = res.data;
          this.$cookieManager.setCookie(
            "_token",
            d.access_token,
            d.expires_at,
            1
          );
          this.$store.commit("dmCommon/changeLoginStatus", true);
          if (this.isNewCustomerActive) {
            window.location.href = this.$route.query.redirect;
            return;
          }
          this.$navigateTo({ text: "注册成功" }, "/personal");
          this.$store.commit("user/setUserInfo", null);
        })
        .catch(err => {
          const data = err.response ? err.response.data : err;
          let msg = data.message;
          this.form.judegeCaptcha(msg);
          if (data.status == 414) {
            msg = this.$t("register.account_presence");
            sessionStorage.setItem("regInfo", JSON.stringify(info));
          }
          this.$parent.accountPresence = data.status == 414;
          this.$parent.popupText = msg;
          this.$parent.isError = true;
          this.form.isLoading = false;
        });
    },
    opCaptcha(n) {
      n == 0
        ? this.$set(this.regInfo, "_captcha", {
            type: "captcha",
            value: "",
          })
        : delete this.regInfo._captcha;
    },
  },
};
</script>
