<template>
  <div class="spotify-downloader">
    <div class="component-header">
      <p class="component-title">
        {{ $t("spotify.download-spotify-to-mp3") }}
      </p>
      <p v-show="!vipInfo.isVip" class="upgrade-btn" @click="buying = true">
        {{ $t("spotify.upgrade") }}
      </p>
    </div>
    <el-input
      v-model="url"
      :class="[
        'input-url',
        status == 'ing' ? 'is-process' : status == 'fail' ? 'error' : '',
        isFocus && 'focus-input',
        isFlash && 'flashing',
        downloadStatus && 'disabled',
      ]"
      :placeholder="$t('spotify.enter-a-spotify-url-of-any-track-to-download')"
      @focus="isFocus = true"
      @blur="isFocus = false"
      @input="handleInputUrl"
    >
      <template slot="append">
        <span></span>
        <template v-if="downloadStatus">
          <el-tooltip
            class="item"
            effect="light"
            :content="$t('spotify.please_wait')"
            placement="top"
          >
            <span @click="handleDownload">
              {{
                status == "ing" ? $t("spotify.cancel") : $t("spotify.search")
              }}
            </span>
          </el-tooltip>
        </template>
        <template v-else>
          <span id="dark-blue" @click="handleDownload">
            {{ status == "ing" ? $t("spotify.cancel") : $t("spotify.search") }}
          </span>
        </template>
      </template>
    </el-input>
    <p v-show="status == 'fail'" class="error-tip">
      <img src="@/assets/img/icon/close-red.svg" alt="close" />
      {{
        $t("spotify.you_may_give_an_incorrect_url_change_a_url_or_try_again")
      }}
    </p>
    <div
      class="grow-container"
      :style="status == 'success' ? '' : 'margin-top: 40px'"
    >
      <template v-if="status == ''">
        <p class="start">{{ $t("spotify.get_started") }}</p>
        <img
          src="@/assets/img/personal/downloader/get-start.png"
          alt="get-start"
        />
      </template>
      <template v-else-if="status == 'ing'">
        <img
          src="@/assets/img/personal/downloader/processing.png"
          alt="processing"
        />
        <div class="processing"></div>
      </template>
      <img
        v-else-if="status == 'fail'"
        src="@/assets/img/personal/downloader/fail.png"
        alt="fail"
      />
      <div class="result-container" v-else>
        <p :class="['table-statistics', tableData.length > 0 && 'no-empty']">
          <span @click="(tableData = []), (needUpgrade = false)">
            <i class="el-icon-delete"></i>
            {{ screenWidth > 576 ? "Clear all" : "" }}
          </span>
          <span>
            {{ $t("spotify.total") }}：
            <span>
              {{ tableData.length || "-" }}
            </span>
          </span>
          <span>
            {{ $t("spotify.selected") }}：
            <span>{{ selectedList.length || "-" }}</span>
            <span class="remark">
              {{ $t("spotify.max_count", { pageSize }) }}
            </span>
          </span>
        </p>
        <div class="table-container">
          <el-table
            :class="[remind && 'remind']"
            style="width: 100%"
            :data="currentPageList"
            ref="resultTable"
            @select="handleSelect"
            @select-all="handleSelectAll"
            @load="handleLoad"
          >
            <el-table-column v-if="screenWidth > 1200" width="20" />
            <el-table-column type="selection" width="45" />
            <el-table-column
              class-name="title-column"
              :label="$t('spotify.title')"
              min-width="40%"
            >
              <template slot-scope="scope">
                <div class="detail">
                  <img :src="scope.row.image" alt="image" />
                  <div>
                    <span class="black">{{ scope.row.name }} </span>
                    <span>{{ scope.row.artist }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="screenWidth > 768"
              class-name="album"
              property="album"
              :label="$t('spotify.album')"
              min-width="35%"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column :label="$t('spotify.duration')" min-width="15%">
              <template slot-scope="scope">
                {{ formatTime(scope.row.duration) }}
              </template>
            </el-table-column>
            <el-table-column v-if="screenWidth > 1200" width="20" />
          </el-table>
          <div class="download-btn-container">
            <template v-if="downloadStatus">
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
                :content="$t('spotify.please_wait')"
              >
                <div
                  class="download-btn has-song no-allowed"
                  @click="handleDownloadList"
                >
                  <img src="@/assets/img/icon/download.svg" alt="download" />
                </div>
              </el-tooltip>
            </template>
            <template v-else>
              <div
                :class="['download-btn', selectedList.length && 'has-song']"
                @click="handleDownloadList"
              >
                <img src="@/assets/img/icon/download.svg" alt="download" />
              </div>
            </template>
          </div>
        </div>
        <div v-show="needUpgrade" class="upgrade-container">
          <p> {{ $t("spotify.upgrade_now") }}</p>
          <p class="p-btn" @click="buying = true">
            {{ $t("spotify.upgrade") }}
          </p>
        </div>
      </div>
      <el-pagination
        v-if="tableData.length > pageSize"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
    <Popup v-if="retryInfo" :isError="true">
      <template v-slot:popup-content>
        <img
          class="close-btn"
          src="@/assets/img/icon/close.png"
          alt="close"
          @click="retryInfo = ''"
        />
        <p class="text">{{ $t("spotify.oops") }} </p>
        <p>
          {{ $t("spotify.unexpected_error") }}
        </p>
        <p class="p-btn usable" id="dark-blue" @click="handleRetry">
          {{ $t("spotify.retry") }}
        </p>
      </template>
    </Popup>
    <el-dialog
      class="refresh-dialog"
      :visible="showRefresh"
      :close-on-click-modal="false"
      @close="needUpgrade = showRefresh = false"
    >
      <p class="bold">{{ $t("spotify.have_you_made_payment") }}</p>
      <p>{{ $t("spotify.click_yes_below") }}</p>

      <div slot="footer">
        <el-button @click="handleRefresh(false)">{{
          $t("spotify.no")
        }}</el-button>
        <el-button id="dark-blue" @click="handleRefresh(true)">
          {{ $t("spotify.yes") }}</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      class="buying-dialog"
      :close-on-click-modal="false"
      :visible="buying"
      @close="buying = needUpgrade = false"
    >
      <div>
        <p class="title"> {{ $t("spotify.get_premium") }}</p>
        <p
          class="sub-title"
          v-html="
            $t('spotify.you_have_songs_left', {
              num: vipInfo.incre_value_music_download?.incre_value_over,
            })
          "
        >
        </p>
        <div class="introduce-table">
          <div></div>
          <div class="header-cell">{{ $t("spotify.free_trial") }}</div>
          <div class="header-cell">{{ $t("spotify.premium") }}</div>
          <div class="header-cell app-cell">
            <i class="el-icon-star-on"> </i>
            {{ $t("spotify.desktop_app") }}
          </div>

          <div>
            {{ $t("spotify.limited_features") }}
            <br />
            {{ $t("spotify.current_plan") }}
          </div>
          <div class="buy">
            <div v-for="(item, key) in vendorInfo.smc.package" :key="key">
              <P>
                <span>{{ item.price }}</span>
                /{{ $t(`spotify.${item.unit}`) }}
              </P>
              <P :class="item.class" @click="toSubscribe(key)">
                <i class="el-icon-shopping-cart-2"></i>
                {{ $t(`spotify.${key}`) }}
              </P>
            </div>
          </div>
          <div class="app-cell download">
            <a :href="vendorInfo.smc.win_download">
              {{ $t("spotify.try_lt_free") }}
              <img src="@/assets/img/icon/win-black.svg" alt="win" />
            </a>
            <a :href="vendorInfo.smc.mac_download">
              {{ $t("spotify.try_lt_free") }}
              <img src="@/assets/img/icon/mac-black.svg" alt="mac" />
            </a>
          </div>

          <div class="row-label">{{ $t("spotify.audio_quality") }}</div>
          <div>128kbps</div>
          <div>{{ $t("spotify.normal") }} (256kbps)</div>
          <div class="app-cell"> 320kbps </div>

          <div class="row-label">{{ $t("spotify.download_format") }}</div>
          <div>MP3</div>
          <div>MP3</div>
          <div class="app-cell small">MP3/M4A/WAV/FLAC </div>

          <div class="row-label">{{ $t("spotify.download_limit") }}</div>
          <div>{{ $t("spotify.two_songs") }}</div>
          <div>
            {{ $t("spotify.unlimited") }}
            <span class="remark">
              ({{ $t("spotify.max_count", { pageSize }) }})
            </span>
          </div>
          <div class="app-cell">{{ $t("spotify.unlimited") }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Popup from "@/components/popup.vue";
import { mapGetters } from "vuex";
// import { EncryptRequest as encrypt } from "@/utils/no-encrypt";
import encrypt from "@/utils/encrypt-new";
// import request from "@/utils/request";

export default {
  name: "spotifyDownloader",
  components: {
    Popup,
  },
  data() {
    return {
      url: "",
      status: "", //未开始:'',进行中:'ing',成功:'success',失败:'fail'
      tableData: [],
      isFocus: false,
      isFlash: false,
      selectedList: [],
      currentPage: 1,
      pageSize: 15,
      req: new encrypt("https://pre-member.tunefab.com"),
      downloadStatus: false,
      retryInfo: "",
      needUpgrade: false,
      showRefresh: false,
      remind: false,
      vipInfo: {},
      buying: true,
    };
  },
  computed: {
    ...mapGetters(["vendorInfo", "screenWidth"]),
    currentPageList() {
      return (
        this.tableData?.slice(
          (this.currentPage - 1) * this.pageSize,
          this.currentPage * this.pageSize
        ) || []
      );
    },
    canSelectCount() {
      return Math.min(
        this.vipInfo.incre_value_music_download?.incre_value_over,
        2
      );
    },
  },
  methods: {
    handleDownload() {
      if (this.status == "ing" || this.downloadStatus) return;
      if (!this.url) return (this.isFlash = true);
      this.status = "ing";
      this.req
        .analyseAudio(this.url)
        .then(res => {
          const that = this;
          this.searchTask(
            res?.data?.key,
            "music_analyse",
            response => {
              that.renderResult(response.data.data);
            },
            () => {
              that.status = "fail";
            }
          );
        })
        .catch(err => {
          this.status = "fail";
          if (
            err.response?.data?.status == 462 &&
            err.response?.data?.message == "Exceeded usage times."
          ) {
            // dlTracking("AnalyzeResult", ["Rejected", this.url, err.data?.message || err]);
            return;
          }
          // dlTracking("AnalyzeResult", ["Failed", this.url, err.data?.message || err]);
          this.retryInfo = {
            event: "analyseAudio",
          };
        });
    },
    searchTask(key, type, callback, handleError) {
      this.req
        .searchTask(key, type)
        .then(res => {
          // const result = res?.data?.data?.status == 200 ? ["Success"] : ["Failed", res.data.error];
          // dlTracking("AnalyzeResult", [...result, this.url]);
          callback && callback(res);
        })
        .catch(() => {
          // dlTracking("AnalyzeResult", ["Failed", this.url, err.data?.message || err]);
          this.retryInfo = {
            event: "searchTask",
            data: { key, type, callback, handleError },
          };
          handleError && handleError();
        });
    },
    renderResult(data) {
      this.status = "success";
      this.tableData = data.list;
    },
    handleSelect(val, item) {
      this.remind = false;
      const previousCount = this.selectedList.length;
      const currentCount = val.length;
      if (
        previousCount < currentCount &&
        currentCount > this.canSelectCount &&
        !this.vipInfo.isVip
      ) {
        this.$refs.resultTable.toggleRowSelection(item, false);
        this.needUpgrade = true;
      } else {
        this.selectedList = val;
      }
    },
    handleSelectAll(val) {
      this.remind = false;
      if (!this.vipInfo.isVip) {
        this.$refs.resultTable.clearSelection();
        this.selectedList = this.currentPageList.slice(0, this.canSelectCount);
        this.selectedList.forEach(item => {
          this.$refs.resultTable.toggleRowSelection(item, true);
        });
        this.needUpgrade = true;
        return;
      }
      this.selectedList = val;
    },
    formatTime(time) {
      let m = Math.floor(time / 1000 / 60);
      let s = Math.floor((time / 1000) % 60);
      return `${m}:${s < 10 ? "0" + s : s}`;
    },
    handleInputUrl() {
      this.isFlash && (this.isFlash = false);
    },
    handleLoad() {
      const newTextNode = document.createTextNode("page");
      document
        .getElementsByClassName("el-pagination__jump")[0]
        .appendChild(newTextNode);
    },
    handleDownloadList() {
      if (this.selectedList.length == 0) return (this.remind = true);
      if (this.downloadStatus) return;
      this.downloadStatus = true;
      this.req
        .downloadList(this.selectedList)
        .then(res => {
          const that = this;
          this.searchTask(res?.data?.key, "music_batch_download", response => {
            window.open(response.data.data.download, "_self");
            that.getRemainCount();
            that.downloadStatus = false;
          });
        })
        .catch(() => {
          this.retryInfo = {
            event: "downloadList",
          };
          this.downloadStatus = false;
        });
    },
    handleRefresh(boolean) {
      this.needUpgrade = false;
      this.showRefresh = false;
      if (!boolean) return;
      console.log("刷新权限");
    },
    handleRetry() {
      const info = this.retryInfo;
      this.retryInfo = "";
      switch (info.event) {
        case "analyseAudio":
          this.handleDownload();
          break;
        case "searchTask":
          const { key, type, callback, handleError } = info.data;
          this.searchTask(key, type, callback, handleError);
          break;
        case "downloadList":
          this.handleDownloadList();
          break;
      }
    },
    getRemainCount() {
      this.req.remainCount().then(res => {
        this.vipInfo = res.data;
      });
    },
    toSubscribe(key) {
      //新标签页打开后
      // showRefresh = true
      alert(`将跳转购物车页面(链接还没出,${key})`);
    },
  },
  mounted() {
    this.getRemainCount();
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/spotifyDownloader";
</style>
