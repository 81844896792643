export default {
  choose_lang: "Italiano",

  common: {
    email: "Email",
    pwd: "Password",
    send: "Send",
    format_error: "格式错误！",
    error: "Errore",
    now: "(ora)",
    vip_system: "Sistema di iscrizione",
    first_name: "Nome",
    last_name: "Cognome",
    tip_first_name: "nome",
    tip_last_name: "cognome",
  },
  login: {
    login: "Accesso",
    title: "Welcome back",
    login_tip: "Please log in to your account",
    save_pwd: "Salva password",
    free_login: "Accesso automatico entro 15 giorni",
    forgot_pwd: "Password dimenticata",
    no_account: "Don't have an account?",
    create_account: "Sign Up",
    email_format_error: "The format of your email address is not correct.",
    pwd_error: "Incorrect password!",
    account_no_exist: "This account does not exist!",
    login_success: "Login successful",
  },
  register: {
    new_account: "Create new account",
    reg_tip: "Fill in this form to create a new account",
    confirm_pwd: "Conferma password",
    reg_account: "Create account",
    terms_and_policy:
      "By signing up, you accept and agree to our <a class='term'>{term}</a> and <a class='policy'>{policy}</a>.",
    term: "Terms of Use",
    policy: "Privacy Policy",
    have_account: "Already have an account? ",
    login: "Log in",
    name_limit: "Il {fieldName} non deve superare i {max} caratteri!",
    email_invalid: "Invalid Email",
    email_no_exist: "This email does not exist",
    pwd_limit:
      "La password deve essere lunga da 8 a 20 caratteri, senza simboli speciali, e deve includere almeno 1 numero e 1 lettera (senza distinzione tra maiuscole e minuscole).",
    pwd_different: "Le password non corrispondono!",
    reg_fail_server:
      "Account creation failed! The server is down for maintenance. Please try again later.",
    reg_fail_net:
      "Account creation failed! Please check your internet connection and try again.",
    reg_success: "Account created successfully ",
    check_tip: "Accetto i termini e le condizioni.",
    please_accept:
      "Si prega di spuntare per accettare i termini e le condizioni.",
    recover_pwd: "Recupera password",
    reset: "Reimposta",
    account_presence: "Questa email esiste già, per favore effettua l'accesso!",
    receive_newsletter: "Iscriviti e ricevi la nostra newsletter.",
  },
  forget: {
    title: "Reset your password",
    tip: "Enter your email address and we'll send password reset instructions.",
    backToLogin: "Return to<a class='loginLink'>{login}</a>",
    login: "log in",
    forget_success: "We've sent you an email to reset your password!",
    send_fail_server:
      "Failed to send! The server is down for maintenance. Please try again later.",
    send_fail_net:
      "Failed to send! Please check your internet connection and try again.",
  },
  update: {
    title: "Change your password",
    tip: "Please enter your new password",
    new_pwd: "Nuova password",
    submit: "Send",
    update_fail_server:
      "Failed to reset! The server is down for maintenance. Please try again later.",
    update_fail_net:
      "Failed to reset! Please check your internet connection and try again.",
    update_success: "Password reset successfully",
  },
  psersonal: {
    sidebarItems: [
      "Informazioni personali",
      "Abbonamento",
      "Gestisci dispositivi",
      "Fatturazione",
      "Contatta il supporto",
      "Servizi a valore aggiunto",
      "Funzionalità del sottoaccount",
      "Cambia password",
      "Carrello della spesa",
      "Spotify Music Converter",
    ],
  },
  userInfoModule: {
    edit: "modifica",
    name: "Nome",
    update: "Aggiorna",
    reg_time: "Ora di registrazione",
    updatePwd: {
      forgot: "Dimenticato",
      current_pwd: "Password attuale",
      current_pwd_p: "Inserisci la password attuale",
      new_pwd_p: "Inserisci una nuova password",
      confirm_pwd_p: "Ripeti la nuova password",
      update_btn: "Aggiorna la password",
    },
    update_success: "Modifica del nome riuscita",
    pwd_update_success:
      "Password modificata con successo, effettua nuovamente l'accesso.",
    profile_update_success: "Cambio dell'avatar riuscito",
    destroy: "Eliminare l'account",
    destroy_tip: "Sei sicuro di voler eliminare il tuo account?",
    destroy_tip2:
      "Questa operazione eliminerà definitivamente il tuo account e i dati correlati. Le funzioni attivate nel tuo account non saranno disponibili dopo il logout riuscito. Procedi con cautela!",
    destroy_success: "Disconnessione dell'account riuscita!",
    pwd_wrong: "Password utente errata!",
    have_sub:
      "Non è possibile annullare un abbonamento con stato di rinnovo normale. Sospendi prima l'abbonamento!",
  },
  subscription: {
    stop_text: "Hai messo in pausa il piano di abbonamento membro per {plan}.",
    cancel_text: "L'abbonamento del tuo {plan} è stato annullato",
    canceling:
      "Il tuo piano di abbonamento premium per {plan} è attualmente in fase di annullamento.",
    renew: "Riprendi l'abbonamento",
    renew_sucess: "Ripresa dell'abbonamento riuscita!",
    expire_date: "Data di scadenza",
    status_text: "Stato dell'abbonamento",
    leave: "Lasciare",
    purchase_date: "Orario di acquisto",
    end_date: "Prossimo pagamento",
    price: "Prezzo",
    num: "Numero di dispositivi supportati",
    account_status: "Stato dell'account",
    no_subscription: "Al momento non hai abbonamenti, ",
    recommend: "forse potresti essere interessato al seguente contenuto",
    learn_more: "Ulteriori informazioni",
    free_download: "Download gratuito",
    trial_limit: "Limitazioni di prova",
    blocked_on: "Bloccato su",
    trial_start: "Orario di inizio della prova",
    start_subscription: "Avvia abbonamento",
    is_trial: "Attualmente sei in stato di prova",
    manage_btn: "Gestisci abbonamento",
    change_payment: "Modifica dettagli di pagamento",
    payment_detail: "Dettagli di pagamento",
    leave_tip: "Sei sicuro di voler lasciare il piano familiare?",
    leave_success: "Hai lasciato con successo il piano familiare!",
    singular_device_num:
      "Dispositivi {type} disponibili: {num1}, dispositivi attivati: {num2}",
    plural_device_num:
      "Dispositivi {type} disponibili: {num1}, dispositivi attivati: {num2}",
    see_detail: "Vedi dettagli",
    active: "Attivo",
    pause: "Pausa",
    overdue: "(Scaduto)",
    update_payment_success:
      "Modifica del metodo di pagamento avvenuta con successo",
    being_processed: "Elaborazione in corso",
  },
  subaccount: {
    invite: "Invita persone ad unirsi",
    send_invite: "Invia un invito",
    manage_title: "Gestisci membri della famiglia",
    remove: "Rimuovi",
    send_success:
      "Hai inviato con successo un'e-mail di invito, in attesa della conferma dall'altra parte!",
    remove_user: "Sei sicuro di voler rimuovere l'utente {name}?",
    all_product: "Tutti i prodotti",
  },
  billing: {
    excl_tax: "(escl. IVA)",
    view_receipt: "Visualizza ricevuta",
    all_time: "Sempre",
    refunded: "Rimborsato",
  },
  manage_devices: {
    reset_all_code: "Reimposta tutti i dispositivi",
    reset_a_code: "Reimposta il dispositivo attuale",
    all_reset_success: "Hai resettato con successo tutti i dispositivi!",
    a_reset_success: "Hai resettato con successo {name}!",
    num_tip: "'è solo {num} opportunità di reset rimasta per quest'anno.",
    a_warning_text: "Sei sicuro di voler ripristinare tutti i dispositivi?",
    all_warning_text: "Sei sicuro di voler ripristinare tutti i dispositivi?",
    device_name: "Nome del dispositivo",
    system: "Sistema",
    reg_time: "Ora di registrazione",
    using_device: "Stai utilizzando {num1} su {num2} dispositivi",
    all: "Tutto",
    residue_num: "Tentativi di reset rimanenti",
    reset_history: "Tempo di cronologia di reset",
    reset_fail_9: "Reset fallito, nessun dato corrispondente per resettare",
    reset_fail_10:
      "Reset fallito, limite superato. Se hai domande, contatta il supporto.",
  },
  contact: {
    support_center: "Centro di supporto",
    support_tip: "Scopri ciò di cui hai bisogno qui",
    feedback: "Feedback",
    submit: "Invia",
    feedback_fail: "Impossibile inviare il feedback, riprova più tardi!",
    feedback_success: "Grazie per il tuo feedback!",
    enter_content: "Inserisci il contenuto del feedback",
    content_placeholder:
      "Per favore, lascia i tuoi suggerimenti o domande qui.",
    select: "Selezionare",
  },

  dm_home: {
    logout: "Disconnettersi",
  },
  dm_login: {
    login_desc: "Bentornato, effettua il login al tuo account.",
    captcha_pl: "Inserisci il codice di verifica",
    or: "o",
    reg: "Registrati",
    enter_tip: "Si prega di inserire il proprio {fieldName}.",
    enter_email: "Inserisci la tua email",
    enter_pwd: "Inserisci la tua password",
    captcha_limit: "La lunghezza del codice di verifica è di 4 caratteri",
    login_expired:
      "Per proteggere la sicurezza del tuo account, devi reinserire la tua password.",
    account_pwd_err: "Account di accesso o errore di password!",
    join_free_download:
      "Iscriviti ad {name} oggi e sblocca <span>download gratuiti</span>",
    new_users_only: "Solo per nuovi utenti",
    bset_solution:
      "Le migliori soluzioni per scaricare video e musica in streaming",
  },
  dm_reg: {
    create: "Crea un account",
    fill_form: "Compila il modulo qui sotto per creare un nuovo account.",
  },
  dm_reset_pwd: {
    reset: "Recupera la tua password",
    reset_pwd: "Reimposta password",
    reset_tip:
      "Inserisci il tuo indirizzo email e ti invieremo istruzioni su come reimpostare la tua password.",
    back_login: "Torna al login",
    email_error: "L'email non esiste",
    reset_success: "Hai reimpostato la tua password con successo.",
    email_had_sent:
      "L'email di reset della password è stata inviata alla casella di posta, si prega di reimpostare la password!",
    no_sign_up:
      "Non c'è ancora un account per questo indirizzo e-mail, per favore iscriviti a un account. ",
  },
  manageSubscription: {
    title: "Gestisci abbonamento",
    desc: "Il tuo piano attuale è:",
    change_methods: "Cambia metodo di abbonamento",
    choose_system_tip:
      "Seleziona il sistema del dispositivo di cui hai bisogno",
    choose_num_tip: "Seleziona il numero di dispositivi di cui hai bisogno",
    enter_num: "Inserisci il numero di dispositivi di cui hai bisogno",
    click_enter: "Clicca per inserire",
    confirm: "Conferma",
    cancel: "Annulla",
    choose_card_tip: "Seleziona il numero della carta di pagamento",
    add_card: "Aggiungi numero di carta",
    choose_way_tip: "Seleziona il metodo di abbonamento desiderato",
    discounts_tip: "Hai ottenuto uno sconto",
    total: "Totale:",
    excl_tax: "(escl. IVA)",
    be_counting: "Calcolando il prezzo",
    end_tip: "La tua data di scadenza è:",
    thank_text:
      "Ancora una volta, grazie di cuore per il vostro continuo sostegno. Non vediamo l'ora di accogliervi nuovamente!",
    back: "Torna all'abbonamento",
    update_success: "Modifica dell'abbonamento riuscita",
    pay_btn: "Paga ora",
    monthy: "Mensile",
    annual: "Annuale",
    life_time: "A vita",
    more: "Altro",
    pay_tip:
      "Cambia metodo di pagamento: La piattaforma addebiterà 0,01 dalla nuova carta e, al successo dell'addebito, potrà essere collegata. Successivamente, l'importo sarà rimborsato al metodo di pagamento originale.",
    is_add: "Aggiungi nuova carta",
    is_set: "Imposta nuova carta",
    cancel_sub: "Metti in pausa l'abbonamento",
    tip: "Se metti in pausa la tua iscrizione ora, puoi comunque utilizzare {name} fino alla fine del ciclo di fatturazione attuale.",
    cancel_success: "Pausa dell'abbonamento riuscita.",
    cancel_text:
      "Hai messo in pausa con successo il piano di abbonamento membro per {productName}. Tuttavia, puoi continuare a utilizzare {productName} fino all'ultimo giorno del tuo abbonamento.",
    are_you_sure: "Sei sicuro di voler mettere in pausa l'abbonamento?",
    request_success: `La tua richiesta è stata inviata con successo; il nostro centro assistenza clienti la elaborerà entro 48 ore. Controlla la tua email da {officialEmail}.`,
  },
  receipt: {
    title: "Ricevuta per {name}",
    back: "Indietro",
    receipt_text: "Ricevuta",
    amount_paid: "Importo pagato",
    date: "Data della ricevuta",
    pay_method: "Metodo di pagamento",
    discount: "Sconto",
    discount_tip: "Gli articoli elencati sono inclusi nel prezzo",
    contact_tip: "Se hai bisogno di aiuto con {brand}, contattaci",
    thank: "Grazie",
    item: "Articoli e Descrizione",
    qty: "Quantità",
    price: "Prezzo",
    amount: "Importo Prelievo Lordo",
    subtotal: "Subtotale",
    total: "TOTALE",
  },
  order_complete: {
    title: "Ordine completato!",
    desc: "Grazie per il tuo acquisto! A breve verrà inviata una email di consegna all'indirizzo email del cliente.",
    order_id: "Il tuo ID ordine",
    price: "Totale ordine",
    name: "Nome del prodotto",
    delivery: "Consegna",
    electronic: "Elettronico",
    unit_price: "Prezzo unitario",
    quantity: "Quantità",
    final: "Prezzo Scontato",
    detail: "Dettagli dell'ordine",
    license_email: "E-mail con licenza",
    license_tip: "La chiave di licenza sarà inclusa nell'e-mail di consegna.",
    contact_text:
      "Se non hai ricevuto l'e-mail di consegna, contatta l'assistenza clienti:",
  },
  popup: {
    ok: "Ok",
    cancel: "Annulla",
    confirm: "Conferma",
    options: ["Problemi software", "Prezzo", "Altro"],
  },
  add_new_card: {
    please_fill: "Si prega di completare le informazioni",
  },
  spotify: {
    "download-spotify-to-mp3": "Scarica Spotify in mp3",
    upgrade: "Aggiornamento",
    "enter-a-spotify-url-of-any-track-to-download":
      "Inserisci l'URL Spotify di qualsiasi traccia da scaricare",
    cancel: "Annulla",
    search: "Cerca",
    get_started: "Copia un URL per iniziare",
    please_wait: "Attendi mentre il tuo file viene scaricato",
    upgrade_now: "Esegui l'aggiornamento ora per download illimitati",
    oops: "Oops! Qualcosa è andato storto.",
    unexpected_error:
      "Si è verificato un errore imprevisto. Controlla la tua connessione e riprova.",
    retry: "Riprova",
    have_you_made_payment: "Hai effettuato il pagamento?",
    click_yes_below:
      "Clicca su 'Sì' qui sotto per aggiornare i vantaggi del tuo abbonamento.",
    no: "No",
    yes: "Sì",
    you_may_give_an_incorrect_url_change_a_url_or_try_again:
      "Puoi fornire un URL errato. Cambia un URL o riprova.",
    max_count: "Massimo {pageSize} brani alla volta",
    clear_all: "Cancella tutto",
    total: "Totale",
    selected: "Selezionato",
    title: "Titolo",
    album: "Album",
    duration: "Durata",
    get_premium: "Ottieni Premium",
    you_have_songs_left:
      "Hai ancora <span>{num}</span> brani. Esegui l'upgrade ora per download illimitati.",
    free_trial: "Prova gratuita",
    premium: "Premio",
    desktop_app: "Applicazione desktop",
    limited_features: "Funzionalità limitate",
    current_plan: "Piano attuale",
    monthly: "Paga mensilmente",
    annually: "Paga annualmente",
    lifetime: "Paga una volta",
    mon: "mese",
    lifelong: "tutta la vita",
    try_lt_free: "Provalo gratis",
    audio_quality: "Qualità audio",
    normal: "Normale",
    download_format: "Formato di download",
    download_limit: "Limite di download",
    two_songs: "2 canzoni",
    unlimited: "Illimitato",
  },
  advertise: {
    desc: "Scarica brani, album, playlist, podcast a una velocità 35 volte superiore.",
    learn_more: "Scopri di più",
  },
  celebrate: "Tutto pronto per i download gratuiti. Inizia subito!",
};
